<template>
  <Authenticated>
    <SingleInput
      :show="showAddCategory"
      message="Category Name:"
      title="Create Category"
      primary="Create"
      @save="create"
    />

    <div>
      <button
        @click="showAddCategory = !showAddCategory"
        class="action-buttons"
      >
        <font-awesome-icon icon="plus" /> Add Category
      </button>

      <div class="actions">
        <h2>Product Categories</h2>
      </div>

      <ul
        class="table wrapper layout content-gap"
      >
        <router-link
          :to="'/products/' + category.value._id.replace(/^.*:/, '')"
          v-bind:key="category"
          v-for="category in categories"
          class="modifierBox"
        >
          <li>
            <div class="box">
              <p style="text-align: center">
                <span class="modifier-name">{{ category.value.name }}</span>
                <span class="modifier-price"
                  >{{ category.value.products.length }} products</span
                >
              </p>
            </div>
          </li>
        </router-link>
      </ul>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "@/components/_layouts/Authenticated";
import SingleInput from "@/components/_layouts/SingleInput";
import { mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";
import Slug from "slug";

export default {
  name: "Modifiers",
  mixins: [createdMixin],
  components: {
    Authenticated,
    SingleInput,
  },

  data() {
    return {
      channel: null,
      currentSet: null,
      currentModifier: null,
      currentDrag: null,
      currentDragOverCategory: null,
      showAddCategory: false,
      tableMode: false,
    };
  },
  mounted() {
    //this.$store.commit("currPage", "products");
  },
  methods: {
    async create(category) {
      //console.log(1);
      let cat = {
        _id: "category:" + Slug(category),
        name: category,
        description: "",
        posOnly: false,
        products: [],
      };
      await this.channel.push("document:new", cat);
      //this.$store.dispatch("updateCategory", cat);
    },
    go(id) {
      this.$router.push("/modifiers/" + id.replace(/^.*:/, ""));
    },
    hasModifiers(currentSet) {
      return (
        currentSet.value &&
        currentSet.value.modifiers &&
        currentSet.value.modifiers.length
      );
    },
  },

  computed: {
    ...mapGetters({
      categories: "categories",
    }),
  },
};
</script>

<style scoped lang="scss">
@import "public/layout";
@import "public/actions";
@import "public/wrapper";
@import "public/toggle";

.add-product {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 10rem;
}
</style>
